















import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'

const comp = {
	name: 'SelectReportsDialog',
	components: {
		ModalDialog,
	},
	props: {
		reports: {
			type: Array,
			required: true,
		},
	},
	methods: {
		closeModal() {
			dlg.close(this)
		},
	},
}

export default comp

export function openSelectReportDlg(reports: any[]) {
	dlg.open(comp, { reports })
}
