<template>
	<modal-wrapper @close="closeModal">
		<div class="select-images-modal">
			<div class="select-images-modal-header">
				<h4>Select Images</h4>
				<ast-button class="close" @click.native="closeModal">
					<svg-icon icon="close" />
				</ast-button>
			</div>
			<p class="selection-info">
				<svg-icon icon="info-circle" class="is-info" />
				Click a thumbnail to
				<strong>exclude</strong>
				that image from being sent.
			</p>

			<div class="thumbnail-container">
				<div v-for="({ study, images }, i) in groupedImages" :key="i" class="thumbnail-study-container">
					<div v-if="study" class="thumbnail-study-header">
						<strong>{{ study.patientId }}</strong> - {{ study.patientName }} on
						{{ study.studyDateTime | localizeDate }} -
						{{ study.modality }}
					</div>
					<ol class="thumbnails">
						<li v-for="image in images" :key="image.key">
							<div
								class="thumbnail"
								alt="Thumbnail preview of first image in series"
								:style="{ 'background-image': `url(${image.thumbUrl})` }"
								@click="image.excluded = !image.excluded"
							>
								<div v-if="image.entireSeries" class="seriesTag">{{ image.countText }}</div>
								<div v-if="image.excluded" class="excluded">Excluded</div>
							</div>
							<div v-if="image.attachmentName">{{ image.attachmentName }}</div>
							<div>{{ image.imageDescription }}</div>
						</li>
					</ol>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import AstButton from '@components/Button'
import { dlg } from '@utils/dlgUtils'

const EmailStudyFormImagesModal = {
	name: 'EmailStudyFormImagesModal',
	components: {
		ModalWrapper,
		AstButton,
	},
	props: {
		studies: {
			type: Array,
			required: false,
		},
		images: {
			type: Array,
			required: true,
		},
	},
	computed: {
		groupedImages() {
			const fillerStudy = {
				study: null,
				images: [],
			}
			const groups = [
				...(this.studies || []).map(study => ({
					study,
					images: [],
				})),
				fillerStudy,
			]
			this.images.forEach(image => {
				const group = groups.find(g => g.study?.studyId === image.studyId) || fillerStudy
				group.images.push(image)
			})
			return groups.filter(group => group.images.length)
		},
	},
	methods: {
		closeModal() {
			dlg.close(this)
		},
	},
}

export default EmailStudyFormImagesModal

export function openEmailStudyImagesDlg(studies, images) {
	dlg.open(EmailStudyFormImagesModal, { studies, images })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.excluded {
	background: var(--primary-bg);
}

.select-images-modal {
	background: var(--secondary-bg);
	border: var(--secondary-border);
	position: relative;
	overflow-x: hidden;
	min-width: 200px;
	max-width: 1200px;
	max-height: 90vh;
	width: 750px;
}
.select-images-modal-header {
	padding-top: 10px;
	h4 {
		text-align: center;
		font-weight: bold;
	}
	.close {
		position: absolute;
		right: 10px;
		top: 10px;
	}
}
.select-images-modal-body {
	padding: 20px;
	width: 650px;
	display: flex;
	justify-content: space-around;
	flex-flow: row wrap;
	align-content: space-between;
	overflow: auto;
}
.thumbnail {
	height: 125px;
	background-size: contain;
	background-color: black;
	background-position: center;
	background-repeat: no-repeat;
	user-select: none;
	position: relative;

	&:hover {
		opacity: 0.8;
	}

	.seriesTag {
		position: absolute;
		top: 10px;
		left: 10px;
		color: white;
	}

	.excluded {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		opacity: 0.9;
		font-weight: 500;
		font-style: italic;
	}
}
.thumbnail-label,
strong,
.thumbnail-images {
	font-size: 0.9em;
	text-align: center;
	word-break: break-word;
	hyphens: auto;
}
strong + .thumbnail-name:before {
	content: ': ';
}
.thumbnails {
	list-style: none;

	li {
		list-style: none;
		display: inline-block;
		min-width: 125px;
		margin: 10px;
		vertical-align: top;
	}
}
.thumbnail-container {
	padding: 10px;
	margin-top: 0px; // Overide
	margin-left: 0px; // Overide
	background: var(--tertiary-bg);
}
.thumbnail-study-header {
	margin: 0 10px;
}
.selected {
	opacity: 1 !important;
}
.selection-info {
	padding: 12px;
	text-align: center;
}
@media (max-width: $mqSmall) {
	.thumbnail-container {
		width: 100%;
		margin-top: 25px;
		margin: 25px auto 0px;
	}
	.thumbnails {
		text-align: center;
	}
	.select-images-modal {
		width: 300px;
		top: 30px;
	}
	.select-images-modal-body {
		width: 300px;
	}
}
</style>
