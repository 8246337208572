<template>
	<div class="menu" :style="{ width }">
		<slot>
			<ul>
				<li
					v-for="(item, i) in items"
					:key="i"
					:class="{ 'has-border': item.hasBorder, 'no-action': item.noAction }"
					@mousedown.prevent="onClick($event, item)"
					@contextmenu.prevent
				>
					<slot name="item" :item="item">
            <svg-icon
              v-if="item.icon"
              :icon="item.icon"
              style="margin-right: 8px;"
            />
            <img
              v-if="item.image"
              :src='`img/${item.image}.svg`'
              alt=""
              style="width:16px; margin-right: 8px;"
            />
						{{ item.label || item }}
					</slot>
				</li>
			</ul>
		</slot>
	</div>
</template>

<script>
export default {
	name: 'ToolbarMenu',
	props: {
		width: {
			type: String,
			default: 'auto',
		},
		items: {
			type: Array,
			default: undefined,
		},
		captureEvent: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onClick(event, item) {
			if (this.captureEvent) {
				this.$emit('select', { event, item })
			} else {
				this.$emit('select', item)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.menu {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	border: 1px solid;
	border-radius: 4px;
	color: var(--primary-label);
	background: var(--toolbar-bg);
	border: 1px solid var(--divider);
	li:hover,
	li:active {
		background: var(--menu-item-hover-bg);
	}
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

ul {
	overflow-y: auto;
	max-height: 75vh;
	width: 100%;
}

li {
	display: block; /* IE/Edge */
	padding: 8px;
	max-width: 90vw;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: pointer;
	&.has-border {
		border-top: 1px solid var(--divider);
	}
	&.no-action {
		font-size: 0.9em;
		cursor: default;
		&:hover {
			background: none;
		}
	}
}
</style>
