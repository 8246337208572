

















import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import EmailStudyForm from '@components/EmailStudyForm.vue'
import { dlg } from '@utils/dlgUtils'

const EmailStudyDlg = {
	name: 'EmailStudyDlg',
	components: {
		DlgHeader,
		ModalWrapper,
		EmailStudyForm,
	},
	props: ['studies', 'images', 'selectedReportIds', 'selectedRelatedStudiesIds'],
	methods: {
		close() {
			dlg.close(this, false)
		},
		onEmailSent() {
			dlg.close(this, true)
		},
	},
}

export default EmailStudyDlg

export function openEmailStudyDlg(
	studies: IStudy[],
	images,
	selectedReportIds: string[] = [],
	selectedRelatedStudiesIds: string[] = []
) {
	return dlg.open(EmailStudyDlg, { studies, images, selectedReportIds, selectedRelatedStudiesIds })
}
