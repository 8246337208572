










import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'

const comp = {
	name: 'SelectRelatedStudiesDialog',
	components: {
		ModalDialog,
	},
	props: {
		relatedStudies: {
			type: Array,
			required: true,
		},
	},
	methods: {
		closeModal() {
			dlg.close(this)
		},
	},
}

export default comp

export function openSelectRelatedStudiesDlg(relatedStudies: any[]) {
	dlg.open(comp, { relatedStudies })
}
