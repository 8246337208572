<template>
	<div>
		<div style="display:flex">
			<div style="margin-bottom: 16px;">
				<button class="btn btn-default" @click="openSelectImagesModal">Select images</button>
				<span style="padding-left: 5px;">({{ includedLength }} selected)</span>
			</div>
			<div v-if="reports.length" style="margin-left:16px; margin-bottom: 16px;">
				<button class="btn btn-default" @click="openSelectReportDlg">Select Reports</button>
				<span style="padding-left: 5px;">({{ reportCount }} selected)</span>
			</div>
			<div v-if="relatedStudies.length" style="margin-left:16px; margin-bottom: 16px;">
				<button class="btn btn-default" @click="openSelectRelatedStudiesDlg">Select Related Studies</button>
				<span style="padding-left: 5px;">({{ relatedStudiesCount }} selected)</span>
			</div>
		</div>
		<form @submit.prevent>
			<!--Email Multi-Select-->
			<label for="email-study-recipient-emails" class="input-label">
				Recipient Email(s)
			</label>
			<email-select id="email-study-recipient-emails" v-model="form.to" class="form-group" />

			<!-- CHECKBOXES -->
			<div class="form-group">
				<ast-checkbox v-model="form.notify">Notify me when images are retrieved</ast-checkbox>
				<ast-checkbox v-model="form.carbonCopy">Send me a copy email</ast-checkbox>
			</div>

			<!-- MESSAGE -->
			<ast-input
				id="email-study-message"
				v-model="form.message"
				class="form-group"
				type="textarea"
				handle-auto-complete
			/>

			<p style="margin-bottom: 15px">
				Sending this e-mail will share patient
				<strong>{{ study.patientName }}</strong>
				and owner
				<strong>{{ study.ownerName }}</strong>
				's
				<strong>{{ study.modality }}</strong>
				study data from
				<strong>
					{{ study.studyDateTime | localizeDate({ forceUTC: false, showTime: false }) }}
				</strong>
				with all recipients.
			</p>

			<button class="btn btn-primary" :class="{ disabled: !canSubmitForm }" @click="sendEmail">
				Send Email
			</button>
			<button class="btn btn-default" @click="$emit('back')">Cancel</button>
		</form>
	</div>
</template>

<script>
import AstInput from '@components/Input'
import { openEmailStudyImagesDlg } from '@components/view/EmailStudyFormImagesModal.vue'
import AstCheckbox from '@components/Checkbox.vue'
import EmailSelect from '@components/EmailSelect.vue'
import { findThumbnailUrl } from '@utils/urlUtility'
import { openSelectReportDlg } from '../dialogs/SelectReportDlg.vue'
import { openSelectRelatedStudiesDlg } from '../dialogs/SelectRelatedStudiesDlg.vue'
import studyData from '@services/studyData'
import GoogleAnalytics from '@services/analyticsService'

export class EmailItem {
	constructor(series, image, entireSeries) {
		this.attachmentName = null
		this.studyId = series.studyId
		this.seriesId = series.seriesId
		this.imageId = image.imageId
		this.entireSeries = entireSeries
		this.imageCount = entireSeries ? series.images.length : 1
		this.imageDescription = image.simpleName
		this.thumbUrl = findThumbnailUrl({
			imageId: image.imageId,
			frameIndex: image.frameIndex,
			storageLocation: series.storageLocation,
		})
		this.excluded = false
	}

	static fromAttachment(a, studyId) {
		let series = {
			studyId,
			seriesId: a.seriesId,
			storageLocation: a.storageLocation,
			images: [],
		}

		let image = {
			imageId: a.imageId,
			frameIndex: a.frameIndex,
		}

		let result = new EmailItem(series, image, false)
		result.attachmentName = a.originalFileName
		return result
	}

	get countText() {
		return `${this.imageCount} ${this.imageCount > 1 ? 'Images' : 'Image'}`
	}

	get key() {
		return `${this.studyId}_${this.seriesId}_${this.imageId}`
	}
}

export default {
	name: 'EmailStudyForm',
	components: {
		AstInput,
		AstCheckbox,
		EmailSelect,
	},
	props: {
		images: {
			type: Array,
			required: true,
		},
		studies: {
			type: Array,
			required: true,
		},
		selectedReportIds: {
			type: Array,
			require: false,
		},
		selectedRelatedStudiesIds: {
			type: Array,
			require: false,
		},
	},
	data() {
		return {
			reports: [],
			relatedStudies: [],
			isSubmitting: false,
			form: {
				to: [],
				subject: `${this.studies[0].patientName} on ${this.$options.filters.localizeDate(
					this.studies[0].studyDateTime,
					{ forceUTC: false, showTime: false }
				)}`,
				notify: false,
				carbonCopy: false,
				message: '',
			},
		}
	},
	computed: {
		study() {
			return this.studies[0]
		},
		includedLength() {
			return this.images.filter(i => !i.excluded).length
		},
		reportCount() {
			return this.reports.filter(r => r.selected).length
		},
		relatedStudiesCount() {
			return this.relatedStudies.filter(r => r.selected).length
		},
		canSubmitForm() {
			let frm = this.form
			return (
				!(!frm.to || frm.to.length === 0) && // not null, empty, or undefined
				!(!frm.subject || frm.subject.length === 0) &&
				frm.message !== null &&
				frm.message !== undefined &&
				!this.isSubmitting
			)
		},
	},
	async mounted() {
		let selectedReportIds = this.selectedReportIds || []
		let selectedRelatedStudiesIds = this.selectedRelatedStudiesIds || []
		let study = this.studies[0]
		let rr = await studyData.getRelatedStudiesAndReports(study.studyId)
		rr.reports.forEach(r => {
			r.selected = selectedReportIds.includes(r.reportId) || selectedReportIds.includes(r.consultantReportId)
		})
		rr.studies.forEach(s => {
			s.selected = selectedRelatedStudiesIds.includes(s.studyId)
		})
		this.reports = rr.reports
		this.relatedStudies = rr.studies
	},
	methods: {
		clearForm() {
			this.form = {
				to: [],
				subject: '',
				notify: false,
				carbonCopy: false,
				message: '',
			}
		},
		async sendEmail() {
			if (!this.canSubmitForm) {
				return
			}

			let formPostData = {
				recipients: this.form.to,
				subject: this.form.subject,
				message: this.form.message,
				notify: this.form.notify,
				carbonCopy: this.form.carbonCopy,
				images: this.images.filter(i => !i.excluded),
				ReportIds: this.reports.filter(r => r.selected).map(r => r.reportId),
				RelatedStudiesIds: this.relatedStudies.filter(r => r.selected).map(r => r.studyId),
			}

			this.isSubmitting = true
			try {
				const response = await this.$api.study.shareByEmail(formPostData)
				if (!response.status || response.status !== 200) throw new Error()
				const data = response.data.data
				this.$emit('email-success', data)
				this.$notifications.addInfo(data)
				try {
					GoogleAnalytics.sendGAEvent('Study', {
						event_category: 'Share By Email',
						event_label: 'Share By Email'
					})
				}
				catch (err) {
					console.log(err)
				}
			} finally {
				this.isSubmitting = false
			}
		},
		openSelectReportDlg() {
			openSelectReportDlg(this.reports)
		},
		openSelectImagesModal() {
			openEmailStudyImagesDlg(this.studies, this.images)
		},
		openSelectRelatedStudiesDlg() {
			openSelectRelatedStudiesDlg(this.relatedStudies)
		},
	},
}
</script>

<style lang="scss" scoped>
button+button {
	margin-left: 15px;
}

.form-group {
	margin-bottom: 15px;
}

.control {
	margin-bottom: 5px;
}
</style>
