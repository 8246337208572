<template>
	<modal-wrapper class="modal-drawer-wrapper modal-wrapper" @close="isDrawerOpen = false" @show="isDrawerOpen = true">
		<transition :name="fromRight ? 'slide-in-left' : 'slide-in-right'" @leave="$emit('close')">
			<div v-show="isDrawerOpen" class="modal-drawer" :class="fromRight && 'from-right'">
				<ast-toolbar :is-primary="true">
					<slot name="buttons"></slot>
					<h3 v-if="title" style="margin: auto 0 auto 16px;">
						{{ title }}
					</h3>
					<toolbar-branding v-else is-compact style="height: 48px;" />
					<toolbar-button icon="close" @click.native="isDrawerOpen = false" />
				</ast-toolbar>
				<div class="modal-content">
					<slot></slot>
				</div>
				<div class="modal-footer">
					<slot name="footer"></slot>
				</div>
			</div>
		</transition>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import AstToolbar from '@components/Toolbar'
import ToolbarButton from '@components/ToolbarButton'
import ToolbarBranding from '@components/ToolbarBranding.vue'
export default {
	name: 'ModalDrawer',
	components: {
		ModalWrapper,
		AstToolbar,
		ToolbarButton,
		ToolbarBranding,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		fromRight: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isDrawerOpen: false,
		}
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.modal-drawer-wrapper {
	.modal-drawer {
		display: flex;
		flex-direction: column;
		position: fixed;
		min-width: 212px;
		max-width: 100vw;
		top: 0;
		height: 100%;
		background: var(--modal-drawer-bg);
		&:not(.from-right) {
			left: 0;
		}
		&.from-right {
			right: 0;
		}
	}
	.modal-content {
		flex-grow: 1;
		overflow-y: overlay;
		overflow-x: hidden;
	}
	.modal-footer {
		flex-grow: 0;
		flex-shrink: 0;
	}
}
</style>
