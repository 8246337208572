







































import ModalWrapper from '@components/ModalWrapper.vue'
import PdfViewer from '@components/PdfViewer.vue'
import { findReportUrl } from '@utils/urlUtility'
import { dlg } from '@utils/dlgUtils'
import ToolbarButton from '@components/ToolbarButton.vue'
import ToolbarMenu from '@components/ToolbarMenu.vue'
import AstCheckbox from '@components/Checkbox.vue'
import { userData } from '@services/userData'
import { mapGetters } from 'vuex'
import { eventBus } from '@services/eventBus'

let resizeDebounce

const ReportPdf = {
	name: 'ReportPdf',
	components: {
		ModalWrapper,
		PdfViewer,
		AstCheckbox,
		ToolbarButton,
		ToolbarMenu,
	},
	props: {
		residentResponseId: {
			type: String,
			default: null,
		},
		reportId: {
			type: String,
			default: null,
		},
		consultantReportId: {
			type: String,
			default: null,
		},
		isComplete: {
			type: Boolean,
			required: true,
		},
		forceConsultantHeader: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isRefreshing: false,
			showWatermark: true,
			useConsultantHeader: true,
			includePrivateFields: false,
		}
	},
	created() {
		this.showWatermark = !this.isComplete
		eventBus.on(eventBus.type.RESIZE, this.onModalDrag)
		const refreshEvents = [
			eventBus.type.REFRESH_REPORT,
			eventBus.type.REPORT_SAVE,
			eventBus.type.REPORT_COMPLETED,
			eventBus.type.REPORT_IMAGE_COMMENT,
		]
		refreshEvents.forEach(e => {
			eventBus.on(e, this.refresh)
		})
		this.$once('beforeDestroy', () => {
			eventBus.off(eventBus.type.RESIZE, this.onModalDrag)
			refreshEvents.forEach(e => {
				eventBus.off(e, this.refresh)
			})
		})
	},
	mounted() {
		this.restorePosition()
	},
	computed: {
		...mapGetters(['isCommunityUser']),
		url() {
			let clinicCode = this.$route.params.clinicCode
			if (!clinicCode && userData.isAuthenticated) clinicCode = userData.claims.activeClinicCode

			// in case the token (containing the clinic code) expired while the PDF viewer was open
			if (!clinicCode) return

			return findReportUrl({
				clinicCode,
				reportId: this.reportId,
				consultantReportId: this.consultantReportId,
				useConsultantHeader: this.useConsultantHeader,
				suppressDraftWatermark: !this.showWatermark,
				includePrivateFields: this.includePrivateFields,
				isResponseVisible: true,
				fontSizeAdjustment: 0,
				residentResponseId: this.residentResponseId,
			})
		},
	},
	methods: {
		async restorePosition() {
			try {
				if (!this.$store.state.auth.token) return
				const reportPdfPosition = await this.$api.user
					.getUserSetting('ReportPDFPosition', 'Telemedicine')
					.then(r => r && r.data)
				if (!reportPdfPosition) return
				const { top, left, width, height } = reportPdfPosition
				if (top || left || width || height) {
					this.$refs.pdfDialog.style.position = 'absolute'
					this.$refs.pdfDialog.style.top = top
					this.$refs.pdfDialog.style.left = left
					this.$refs.pdfDialog.style.width = width
					this.$refs.pdfDialog.style.height = height
				}
				this.onModalDrag()
			} catch (err) {}
		},
		refresh() {
			this.isRefreshing = true
			this.$nextTick(() => {
				this.isRefreshing = false
			})
		},
		close() {
			if (this.$refs.pdfDialog && this.$store.state.auth.token) {
				const { top, left, width, height } = this.$refs.pdfDialog.style
				this.$api.user.setUserSetting('ReportPDFPosition', 'Telemedicine', JSON.stringify({ top, left, width, height }))
			}
			dlg.close(this, true, null)
		},
		onModalResize() {
			// trigger PDF rescaling on a debounce
			clearTimeout(resizeDebounce)
			resizeDebounce = setTimeout(eventBus.post, 100, eventBus.type.RESIZE)
		},
		onModalDrag() {
			if (!this.$refs.pdfDialog) return
			// ensure dialog is not outside the viewport
			const rect = this.$refs.pdfDialog.getBoundingClientRect()
			const bounds = {
				width: window.innerWidth || document.documentElement.clientWidth,
				height: window.innerHeight || document.documentElement.clientHeight,
			}
			let resized = false
			if (rect.width > bounds.width) {
				this.$refs.pdfDialog.style.width = ''
				resized = true
			}
			if (rect.height > bounds.height) {
				this.$refs.pdfDialog.style.height = ''
				resized = true
			}
			if (resized) return this.$nextTick(this.onModalDrag)
			if (rect.left < 0) this.$refs.pdfDialog.style.left = '0'
			if (rect.right > bounds.width) {
				this.$refs.pdfDialog.style.left = bounds.width - rect.width + 'px'
			}
			if (rect.top < 0) this.$refs.pdfDialog.style.top = '0'
			if (rect.bottom > bounds.height) {
				this.$refs.pdfDialog.style.top = bounds.height - rect.height + 'px'
			}
		},
	},
}

export default ReportPdf

export function openReportPdf(
	reportId: string,
	consultantReportId: string,
	isComplete = true,
	residentResponseId: string = null,
	forceConsultantHeader: boolean = false
): Promise<IPdfViewerResult> {
	return dlg.open(ReportPdf, {
		reportId,
		consultantReportId,
		isComplete,
		residentResponseId,
		forceConsultantHeader,
	})
}
