<template>
	<div class="branding" :class="{ compact: isCompact }" :title="title">
		<svg
			class="logo"
			:class="{ community: isCommunityUser }"
			width="167" height="25"
			xmlns="http://www.w3.org/2000/svg"
			clip-rule="evenodd"
		>
      <image
        :href='require("@/assets/compressed/asteris-omni-logo-white.png")' :alt="title" width="167" height="25"
             fill="currentColor"
      />
		</svg>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'ToolbarBranding',
	props: {
		isCompact: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters(['isCommunityUser']),
		title() {
			if (this.isCommunityUser) return 'Keystone Community'
			else return 'Keystone Omni'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

@media (min-width: $mqMedium) {
	.branding {
		display: flex !important;
		align-items: center;
		padding-right: 1em;
		font-size: 1.1em;
		font-weight: 500;
	}
}
.branding:not(.compact) {
	display: none;
}
.logo {
	width: 170px;
	height: 28px;
	margin: 10px;
	color: var(--primary-toolbar-logo);
	&.community {
		color: var(--primary-toolbar-logo-community);
	}
}

</style>
